import { action, thunk } from 'easy-peasy';
import axios from 'axios';
import setAuthToken from './utils/setAuthToken';
import findPropsAcumatica from './utils/findPropsAcumatica';
import { v4 } from 'uuid';
import qs from 'qs';

axios.defaults.baseURL = 'https://cfbswufoo.azurewebsites.net/';
//axios.defaults.baseURL = 'https://cfbswufoodev.azurewebsites.net/';
//axios.defaults.baseURL = 'http://localhost:8080/';

export default {
  baseURL: axios.defaults.baseURL,
  /* #region  Intial State */
  isAuthenticated: null,
  loading: false,
  user: { email: '', password: '' },
  client: {
    client: '',
    guid: '',
    baseURL: '',
    acmUser: '',
    acmPwd: '',
    acmClientID: '',
    acmSecret: '',
    acmCompanyKey: '',
    acmEndpointName: '',
    acmEndpointVersion: '',
    wufooSubdomain: '',
    wufooAPIKey: '',
    wufooHandshakeKey: '',
    acumaticaConfirmed: '',
    wufooConfirmed: '',
  },
  ipAddress: '',
  showFormConfigure: false,
  popupMessage: null,
  error: null,
  alerts: [],
  current: null,
  forms: [],
  filteredForms: null,
  currentFormIndex: null,
  formFields: null,
  wufooDropdownOpen: false,
  wufooDropdownValue: null,
  acumaticaFields: [],
  acumaticaDefaultFields: [],
  acumaticaDropdownOpen: false,
  acumaticaDropdownValue: null,
  acumaticaTypeFields: [],
  acumaticaTypeDropdownOpen: false,
  acumaticaTypeDropdownValue: 'Leads',
  inventoryValue: 1,
  mapArray: [],
  mapDirty: false,
  newUser: {
    name: '',
    email: '',
    password: '',
    password2: '',
    role: 'user',
    company: '',
  },
  /* #endregion */

  /* #region  Thunks */
  /* #region  Load User */
  loadUser: thunk(async (actions) => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    //@todo - load token into global headers
    try {
      if (localStorage.token) {
        const user = await axios.get('/api/v1/auth/me');
        if (user.data.success) {
          actions.setAuthenticated(true);
          actions.setUser(user.data);
          // const client = await axios.get(
          //   `/api/v1/client/${user.data.clientGuid}`
          // );
          // actions.setClient(client.data);
        } else {
          actions.cleanUp();
        }
      }
    } catch (err) {
      actions.cleanUp();
    }
  }),
  /* #endregion */

  /* #region  Register User */
  register: thunk(async (actions, user) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await axios.post('/api/v1/users', user, config);
      actions.setAlerts({
        error: `User ${user.name} created for the ${user.company} company`,
        type: 'success',
      });
      actions.clearNewUser();
    } catch (err) {
      console.log(err.message);
      const msg = err.response ? err.response.data.msg : err.message;
      actions.setAlerts({
        error: msg,
        type: 'danger',
      });
    }
  }),
  /* #endregion */

  /* #region  Login User */
  login: thunk(async (actions, formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const user = await axios.post('/api/v1/auth/login', formData, config);
      if (user.data.success) {
        setAuthToken(user.data.token);
        actions.setLocalStoragToken(user.data.token);
        actions.setAuthenticated(true);
        actions.loadUser();
      } else {
        actions.cleanUp();
      }
    } catch (err) {
      actions.cleanUp();
      const msg = err.response ? err.response.data.msg : 'Server error';
      actions.setAlerts({ error: msg, type: 'danger' });
      actions.setError(
        (err.response && err.response.data.msg) || 'Server error'
      );
      //actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  Logout User */
  logout: thunk((actions) => {
    actions.setLoading(true);
    setAuthToken('');
    actions.cleanUp();
  }),
  /* #endregion */

  /* #region  Set Alerts */
  setAlerts: thunk((actions, alert) => {
    alert.id = v4();
    actions.setAlert(alert);
    setTimeout(() => {
      actions.removeAlert(alert);
      actions.setLoading(false);
    }, 3000);
  }),
  /* #endregion */

  /* #region  Get Forms */
  getForms: thunk(async (actions) => {
    try {
      const formsRes = await axios.get('/api/v1/wufoo');
      let forms = [];
      if (formsRes.data.sucess) {
        forms = formsRes.data.Forms;

        const activeRes = await axios.get(`/api/v1/map`);
        const activeForms = activeRes.data;
        activeForms.forEach((a) => {
          const idx = forms.findIndex((i) => i.Hash === a.hash);
          if (idx >= 0) {
            forms[idx].WebhookActive = a.webhookActive;
          }
        });
      } else {
        forms = [
          {
            Name: 'No Wufoo Forms Found',
            Description: '',
            DateCreated: new Date(),
          },
        ];
      }

      actions.setForms(forms);
      actions.setLoading(false);
    } catch (err) {
      actions.setAlerts({ error: 'Wufoo forms not found', type: 'warning' });
      actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  Get Client */
  getClient: thunk(async (actions, payload) => {
    const clientRes = await axios.get(`/api/v1/client/${payload}`);
    const client = clientRes.data;
    client.acmPwd = '';

    if (client.success) {
      actions.setClient(client);
    }
  }),
  /* #endregion */

  /* #region  Update Client */
  updateClient: thunk(async (actions, payload) => {
    const data = {
      payload,
    };

    const clientRes = await axios.put(`/api/v1/client/${payload.guid}`, data);

    const client = clientRes.data;
    if (client.success) {
      actions.setClient(client);
      actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  Get Wufoo Form Fields */
  getWufooFormFields: thunk(async (actions, payload) => {
    const wufooFormFieldsRes = await axios.get(`/api/v1/wufoo/${payload}`);
    //console.log(wufooFormFieldsRes);

    if (wufooFormFieldsRes.data.success) {
      let wuFooFields = wufooFormFieldsRes.data.fields;
      wuFooFields.push({
        Field: 'EntryId',
        Title: 'Entry Identifier',
      });
      actions.setFormFields(wuFooFields);
      //actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  Get Acumatica Fields */
  getAcumaticaFields: thunk(async (actions, payload) => {
    const acumaticaFieldsRes = await axios.get(`/api/v1/options/${payload}`);
    if (acumaticaFieldsRes.data.success) {
      actions.setAcumaticaFields(
        JSON.parse(acumaticaFieldsRes.data.fieldsJSON)
      );
      //actions.setLoading(false);
    }
  }),
  /* #endregion */

  /* #region  Get Form Map */
  getFormMap: thunk(async (actions, payload) => {
    try {
      const { Hash, client } = payload;
      const map = await axios.get(`/api/v1/map/${client}/${Hash}`);
      if (map.data.fieldMap) {
        const json = JSON.parse(map.data.fieldMap);
        const keys = Object.keys(json);
        keys.forEach((k) => {
          let mapString = '';
          if (typeof json[k] !== 'object') {
            mapString = `{"${k}": "${json[k]}"}`;
            actions.setMapArray(JSON.parse(mapString));
          } else {
            const objKeys = Object.keys(json[k]);
            const keysLen = objKeys.length;

            for (let i = 0; i < keysLen; i++) {
              mapString = `{"${k}.${Object.keys(json[k])[i]}": "${
                json[k][Object.keys(json[k])[i]]
              }"}`;
              actions.setMapArray(JSON.parse(mapString));
            }
          }
        });
      }
      actions.setAcumaticaTypeDropdownValue(map.data.mapType || 'Sales');
      actions.setInventoryValue(map.data.inventoryCount || 1);
      actions.setShowFormConfigure(true);

      return { success: true };
    } catch (err) {
      return { success: false, msg: err };
    }
  }),
  /* #endregion */

  /* #region  Upsert Form Map */
  upsertFormMap: thunk(async (actions, payload) => {
    try {
      const {
        client,
        hash,
        fieldMap = null,
        webhookActive = false,
        mapType = null,
        inventoryCount = null,
      } = payload;
      const data = {
        client,
        hash,
        fieldMap,
        webhookActive,
        mapType,
        inventoryCount,
      };
      await axios.post(`/api/v1/map`, data);

      return { success: true };
    } catch (err) {
      return { success: false, msg: err };
    }
  }),
  /* #endregion */

  /* #region  Put WebHook */
  putWebHook: thunk(async (actions, payload, helpers) => {
    try {
      const hash = payload.Hash;
      const url = `${helpers.getState().baseURL}api/v1/webhook`;

      const data = qs.stringify({
        url,
        hash,
      });

      await axios.put(`/api/v1/wufoo`, data);

      const client = payload.client;
      const fieldMap = null;
      const webhookActive = true;

      await actions.upsertFormMap({
        client,
        hash,
        fieldMap,
        webhookActive,
        mapType: null,
      });
      await actions.getForms();
      actions.setAlerts({ error: 'Webhook enabled', type: 'success' });

      actions.setLoading(false);

      return { success: true };
    } catch (err) {
      return { success: false, msg: err };
    }
  }),
  /* #endregion */

  /* #region  Verify Acumatica */
  verifyACM: thunk(async (actions, client) => {
    try {
      await axios.get(`/api/v1/verify/acumatica`);

      actions.setAlerts({
        error: 'Successful Acumatica Authentication',
        type: 'success',
      });

      actions.setLoading(false);
      await actions.getClient(client.guid);
    } catch (err) {
      // return { success: false, msg: err };
      actions.setAlerts({
        error: err.message,
        type: 'danger',
      });
    }
  }),
  /* #endregion */

  /* #region  Verify Wufoo */
  verifyWufoo: thunk(async (actions, client) => {
    try {
      await axios.get(`/api/v1/verify/wufoo`);

      actions.setAlerts({
        error: 'Successful Wufoo Authentication',
        type: 'success',
      });

      actions.setLoading(false);
      await actions.getClient(client.guid);
    } catch (err) {
      actions.setAlerts({
        error: err.message,
        type: 'danger',
      });
    }
  }),
  /* #endregion */

  getTypes: thunk(async (actions, client) => {
    try {
      const response = await axios.get(`/api/v1/types`);
      actions.setAlerts({
        error: 'Successful Wufoo Authentication',
        type: 'success',
      });
      actions.setAcumaticaTypeFields(response.data);
      actions.setLoading(false);
    } catch (err) {
      actions.setAlerts({
        error: err.message,
        type: 'danger',
      });
    }
  }),

  /* #endregion Thunks */

  /* #region  Actions */
  setLocalStoragToken: action((state, token) => {
    localStorage.setItem('token', token);
    //state.token = token;
  }),
  setUser: action((state, user) => {
    state.user = user;
  }),
  setAuthenticated: action((state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setClient: action((state, client) => {
    const keys = Object.keys(state.client);
    keys.forEach((k) => {
      client[k] ? (state.client[k] = client[k]) : (state.client[k] = '');
    });

    //state.client = client;
  }),
  setShowPopup: action((state, show) => {
    state.showPopup = show;
  }),
  setIPAddress: action((state, ip) => {
    state.ipAddress = ip;
  }),
  cleanUp: action((state) => {
    localStorage.removeItem('token');
    state.isAuthenticated = null;
    state.user = { email: '', password: '' };
    state.client = {
      client: '',
      guid: '',
      baseURL: '',
      acmUser: '',
      acmPwd: '',
      acmClientID: '',
      acmSecret: '',
      acmCompanyKey: '',
      acmEndpointName: '',
      acmEndpointVersion: '',
      wufooSubdomain: '',
      wufooAPIKey: '',
      wufooHandshakeKey: '',
      acumaticaConfirmed: '',
      wufooConfirmed: '',
    };
    state.ipAddress = '';
    state.showPopup = false;
    state.popupMessage = null;
    state.current = null;
    state.filtered = null;
    state.forms = [];
    state.loading = false;
  }),
  setError: action((state, msg) => {
    state.error = msg;
  }),
  clearError: action((state) => {
    state.error = null;
  }),
  setAlert: action((state, alert) => {
    state.alerts = [...state.alerts, alert];
  }),
  removeAlert: action((state, alert) => {
    state.alerts = state.alerts.filter((a) => a.id !== alert.id);
  }),
  setForms: action((state, forms) => {
    state.forms = forms;
  }),
  setFilterText: action((state, text) => {
    state.filterText = text;
  }),
  setFilteredForms: action((state, text) => {
    let rValue = state.forms.filter((f) => {
      const regex = new RegExp(`${text}`, 'gi');
      return f.Name.match(regex);
    });

    if (rValue.length === 0) {
      rValue = [];
    }
    state.filteredForms = rValue;
  }),
  clearFormsFilter: action((state) => {
    state.filteredForms = null;
  }),
  setShowFormConfigure: action((state, show) => {
    state.showFormConfigure = show;
  }),
  processFormConfigureCleanup: action((state) => {
    state.loading = false;
  }),
  setCurrentFormIndex: action((state, idx) => {
    state.currentFormIndex = idx;
  }),
  setFormFields: action((state, fields) => {
    state.formFields = fields;
  }),
  setWufooDropdownOpen: action((state, open) => {
    state.wufooDropdownOpen = open;
  }),
  setWufooDropdownValue: action((state, value) => {
    state.wufooDropdownValue = value;
  }),
  setAcumaticaFields: action((state, fields) => {
    const acmFields = findPropsAcumatica(fields);
    state.acumaticaDefaultFields = acmFields;
    updateFields(state);
  }),
  updateAcumaticaFields: action((state) => {
    updateFields(state);
  }),
  setAcumaticaDropdownOpen: action((state, open) => {
    state.acumaticaDropdownOpen = open;
  }),
  setAcumaticaTypeFields: action((state, types) => {
    state.acumaticaTypeFields = types;
  }),
  setAcumaticaTypeDropdownOpen: action((state, open) => {
    state.acumaticaTypeDropdownOpen = open;
  }),
  setInventoryValue: action((state, amount) => {
    state.inventoryValue = parseInt(amount, 10);
  }),
  setAcumaticaDropdownValue: action((state, value) => {
    state.acumaticaDropdownValue = value;
  }),
  setAcumaticaTypeDropdownValue: action((state, value) => {
    state.acumaticaTypeDropdownValue = value;
  }),
  setMapArray: action((state, obj) => {
    state.mapArray.push(obj);
    //state.mapArray = { ...state.mapArray, obj };
    state.wufooDropdownValue = null;
    state.acumaticaDropdownValue = null;
  }),
  removeMapItem: action((state, item) => {
    state.mapArray = state.mapArray.filter((i) => Object.keys(i)[0] !== item);
  }),
  cleaupConfigureModal: action((state) => {
    state.formFields = null;
    state.wufooDropdownValue = null;
    state.acumaticaDropdownValue = null;
    state.acumaticaFields = null;
    state.mapArray = [];
  }),
  setMapDirty: action((state, dirty) => {
    state.mapDirty = dirty;
  }),
  setNewUser: action((state, nameValue) => {
    state.newUser[Object.keys(nameValue)[0]] =
      nameValue[Object.keys(nameValue)[0]];
  }),
  clearNewUser: action((state) => {
    state.newUser = {
      name: '',
      email: '',
      password: '',
      password2: '',
      role: 'user',
      company: '',
    };
  }),

  /* #endregion */
};

const updateFields = (state) => {
  const newFields = state.acumaticaDefaultFields.filter(
    (item) => !item.Field.startsWith('Details')
  );
  if (state.acumaticaTypeDropdownValue === 'Sales') {
    const detailFields = state.acumaticaDefaultFields.filter((item) =>
      item.Field.startsWith('Details')
    );
    for (let i = 0; i < state.inventoryValue; i++) {
      detailFields.forEach((item) => {
        newFields.push({ Field: `${item.Field}.${i + 1}` });
      });
    }
  }
  state.acumaticaFields = newFields || [];
};
